<template>
	<div class="row justify-content-center">
		<div class="col-12 col-lg-10">
			<b-alert
				v-if="admin.includes($user.details().type)"
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							v-model="filters.userId"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:default-params="{role:'distributor', validateCountry: false}"
							:placeholder="'autocomplete_placeholder'"
							class="mb-3"
							q="label"
							return-key="id" />
						<b-button
							variant="primary"
							@click="getDataFiltered">
							{{ translate('search') }}
						</b-button>
						<b-button
							variant="secondary"
							class="ml-1"
							@click="clearFilters">
							{{ translate('clear') }}
						</b-button>
					</div>
				</div>
			</b-alert>
			<div
				v-if="!loadingVideos && hasVideos"
				class="row">
				<div
					v-for="video in videos"
					:key="video.id"
					class="col-12 col-sm-6 col-md-4 col-lg-3">
					<video-preview
						:id="video.id"
						:title="translate(video.attributes.code_name)"
						:author="video.attributes.author"
						:video-url="video.attributes.link"
						:thumbnail-src="`${S3_PATH}/assets/videos/${theme}/${video.attributes.code_name}.jpg`"
						:blocked="!video.attributes.is_available"
						:expiration="video.attributes.is_available ? translate('expires_on', { expiration: $moment(video.attributes.end_date.date).format(datetimeFormat) }) : translate('expired')" />
				</div>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loadingVideos"
				:has-data="hasVideos" />
		</div>
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import VideoPreview from '@/components/VideoPreview';
import { MMMDYHMA_FORMAT } from '@/settings/Dates';
import { S3_PATH } from '@/settings/Images';
import { Products } from '@/translations';
import Videos from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import FiltersParams from '@/mixins/FiltersParams';
import { admin } from '@/settings/Roles';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Videos',
	messages: [Products],
	components: {
		IsLoading,
		VideoPreview,
	},
	mixins: [FiltersParams],
	data() {
		return {
			theme: process.env.VUE_APP_THEME,
			S3_PATH,
			datetimeFormat: MMMDYHMA_FORMAT,
			videosData: new Videos(),
			endpoint,
			admin,
		};
	},
	computed: {
		videos() {
			try {
				return this.videosData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingVideos() {
			return this.videosData.data.loading;
		},
		hasVideos() {
			return !!this.videos.length;
		},
		username() {
			try {
				return this.videosData.data.response.data.meta.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
	},
	watch: {
		username(user) {
			this.$parent.titleParam = { user };
		},
	},
	mounted() {
		this.videosData.getVideos(this.$user.details().id);
	},
	methods: {
		getDataFiltered() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			const userId = this.$user.details().id;
			this.videosData.getVideos(userId, options);
		},
	},
};
</script>
